// Default tag styles

html, body {
    height: 100%;
}

body {
    font: 14px/19px @elektra;
    font-weight: 300;
    color: #333333;
    background-color: #fff;
    -webkit-text-size-adjust: none;
}

body, input, textarea, button {
    -webkit-font-smoothing: antialiased;
    outline: none !important;
}

ul, li {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

ins {
    text-decoration: none;
}

strong, b {
	font-weight: 700;
}

hr {
    display: block;
    width: 143px;
    border-top: 3px solid #222222;
    margin: 20px 0;
}



h1 {

}

h2 {
    font-family: @molot;
    font-size: 47px;
    line-height: 1;
    margin-bottom: 25px;
}

h3 {
    font-size: 19px;
    line-height: 24px;
    font-weight: 400;

    i {
        display: inline-block;
        vertical-align: middle;
    }
}

h4 {
    font-size: 14px;
    line-height: 19px;
    font-weight: 700;

}

a {
    color: #222222;
    text-decoration: none;
    outline: none !important;
    .m-trans(all);

    &:hover {
        color: #cc0000;
        text-decoration: none;
    }

    &:focus {
        color: #222222;
    }
}

img {
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
    filter: gray; 
    .m-trans(all);

    &:hover {
        -webkit-filter: none;
        -moz-filter: none;
        -ms-filter: none;
        -o-filter: none;
        filter: none;
        filter: none; 
    }   
}