.btn {
    
}

.form {
    

    &__input{
  

    }

    &__text {
        
    }
}

/*	=	Select */

.select {
	position: relative;
	z-index: 10;
	width: 210px;
	font-size: 19px;
	font-weight: 400;
    line-height: 24px;
    margin: 0 0 25px;

	a {
		text-decoration: none !important;
	}
}

.slct {
	display: block;
	background-color: #e8e8e8;
	width: 100%;
	padding: 10px 15px 9px;
	overflow: hidden;
	white-space:nowrap;
	text-overflow: ellipsis;
	-o-text-overflow: ellipsis;	

	&:hover {
		background-color: #d0d0d0;
		color: #222222;
	}

	i {
		float: right;
		color: #aaaaaa;
		font-size: 24px;
	}

}
.drop {
	margin: 0 !important;
	padding: 0;
	width: 100%;
	display: none;
	position: absolute;
	background: #e8e8e8;

	li {
		list-style: none;
		cursor: pointer;
		display: block;		
	}

	a {
		display: block;
		padding: 10px 15px 9px;

		&:hover {
			background-color: #d0d0d0;
			color: #222222;
		}
	}
}
