/* Template-specific stuff
 *
 * Customizations just for the template; these are not necessary for anything
 * with disabling the responsiveness.
 */

/* Account for fixed navbar */
body {
  	min-width: 960px;
}

/* Finesse the page header spacing */
.page-header {
  	margin-bottom: 30px;
}

.page-header .lead {
  	margin-bottom: 10px;
}


/* Non-responsive overrides
 *
 * Utilitze the following CSS to disable the responsive-ness of the container,
 * grid system, and navbar.
 */

/* Reset the container */
.container {
  	width: 930px;
  	max-width: none !important;
}

/* Demonstrate the grids */

.container .navbar-header,
.container .navbar-collapse {
  	margin-right: 0;
  	margin-left: 0;
}

/* Always float the navbar header */
.navbar-header {
  	float: left;
}

/* Undo the collapsing navbar */
.navbar-collapse {
  	display: block !important;
  	height: auto !important;
  	padding-bottom: 0;
  	overflow: visible !important;
}

.navbar-toggle {
  	display: none;
}

.navbar-collapse {
  	border-top: 0;
}

.navbar-brand {
  	margin-left: -15px;
}

/* Always apply the floated nav */
.navbar-nav {
  	float: left;
  	margin: 0;
}

.navbar-nav > li {
  	float: left;
}

.navbar-nav > li > a {
  	padding: 15px;
}

/* Redeclare since we override the float above */
.navbar-nav.navbar-right {
  	float: right;
}

/* Undo custom dropdowns */
.navbar .navbar-nav .open .dropdown-menu {
  	position: absolute;
  	float: left;
  	background-color: #fff;
  	border: 1px solid #ccc;
  	border: 1px solid rgba(0, 0, 0, .15);
  	border-width: 0 1px 1px;
  	border-radius: 0 0 4px 4px;
  	-webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
}

.navbar-default .navbar-nav .open .dropdown-menu > li > a {
  	color: #333;
}

.navbar .navbar-nav .open .dropdown-menu > li > a:hover,
.navbar .navbar-nav .open .dropdown-menu > li > a:focus,
.navbar .navbar-nav .open .dropdown-menu > .active > a,
.navbar .navbar-nav .open .dropdown-menu > .active > a:hover,
.navbar .navbar-nav .open .dropdown-menu > .active > a:focus {
  	color: #fff !important;
  	background-color: #428bca !important;
}

.navbar .navbar-nav .open .dropdown-menu > .disabled > a,
.navbar .navbar-nav .open .dropdown-menu > .disabled > a:hover,
.navbar .navbar-nav .open .dropdown-menu > .disabled > a:focus {
  	color: #999 !important;
  	background-color: transparent !important;
}