// Mixin example



.m-trans(@prop) {
	transition: @prop .3s ease;
}

.m-noselect() {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
