// Header

.header {
	padding: 12px 0;
	margin-bottom: 10px;


	&__phone {
		display: inline-block;
		font-size: 19px;
		line-height: 19px;
		color: #888888;
		margin: 50px 0 12px;
		text-decoration: none !important;

		&::before {
			content:'';
			display: inline-block;
			background: url(../images/sprite.png) 0 -18px no-repeat;
			width: 16px;
			height: 17px;
			vertical-align: top;
			margin-right: 5px;
		}
	}
}



.logo {
	font-family: @molot;
	font-size: 47px;
	font-weight: 400;
	line-height: 1;
	color: #333333;
	margin-top: 45px;

	a {
		display: inline-block;
		position: relative;
		width: 327px;
		height: 75px;
		background: url(../images/logo.png) 0 0 no-repeat;
		transition: none;
		
		&::before {
			content: '';
			display: block;
			position: absolute;
			background: url(../images/logo.png) 0 -75px no-repeat;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			opacity: 0;
			z-index: 1;
			transition: opacity 0.3s;
		}

		&:hover::before {
			opacity: 1;
		}
	}

	span {
		display: block;
		float: left;
		font-family: @elektra;
		font-weight: 300;
		font-size: 19px;
		color: #888888;
	}
	
	&:active,
	&:focus {
		text-decoration: none;
	}
}




.navmain {
	margin: 12px 0;

	li {
		display: inline-block;
		margin-left: 4px;
	}

	a {
		display: block;
		font-size: 19px;
		line-height: 24px;
		padding: 6px 10px 4px;
		text-transform: uppercase;
		color: #333333;
		text-decoration: none !important;
		transition: all 0.4s ease;

		&:hover {
			color: #cc0000;
		}

		
		&.is-active {
			background-color: #efefef;
			color: #333333;
		}
	}
}



