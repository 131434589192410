 // Footer

@footerHeight: 80px;

.wrapper {
    position: relative;
    min-height: 100%;
    height: auto !important;
    height: 100%;
}

.wrapper .empty {
    height: @footerHeight;
}

.footer {
    position: relative;
    margin-top: -@footerHeight;
    height: @footerHeight;
	
}

.footer-main {
	border-top: 2px solid #bfbfbf;
	padding: 27px 0;
	font-size: 14px;
	line-height: 19px;
}

.copy {
	float: left;
	line-height: 32px;
}

.share {
	float: right;

	&__title {
		display: inline-block;
		vertical-align: middle;
	}

	img {
		display: inline;
		-webkit-filter: none;
        -moz-filter: none;
        -ms-filter: none;
        -o-filter: none;
        filter: none;
        filter: none; 
	}
}

.footer-login {
	display: inline-block;
	position: relative;
	top:-2px;
	margin-left: 30px;

	a {
		display: inline-block;
		font-size: 14px;
		line-height: 19px;
		padding: 8px 15px;
		color: #000;
		border-radius: 10px;
		text-decoration: none !important;
		position: relative;
		padding-left: 38px;

		&::before,
		&::after {
			content:'';
			background: url(../images/sprite.png) 0 0 no-repeat;
			width: 12px;
			height: 15px;
			display: block;
			position: absolute;
			left: 16px;
			top: 50%;
			margin-top: -9px;
			opacity: 1;
			.m-trans(opacity);
		}

		&::after {
			background-position: -15px 0;
			opacity: 0;
		}
	}

	&__in {
		display: inline-block;
		position: relative;
		z-index: 999;

		&:hover {
			color: #cc0000;

			&::before {
				opacity: 0;
			}

			&::after {
				opacity: 1;
			}
		}

		&.is-active {
			background-color: #ebebeb;
			

			&:hover {
			color: #000;

				&::before {
					opacity: 1;
				}

				&::after {
					opacity: 0;
				}
			}
		}
	}

	&__out {
		display: none !important;
	}
}


.form {
	display: block;
	position: absolute;
	bottom: 48px;
	left: 50%;
	margin-left: -112px;
	width: 225px;
	height: 147px;
	padding: 30px 25px;
	background-color: #fff;
	border-radius: 9px;
	text-align: center;
	display: none;
	z-index: 999;
	

	&:before {
		content: '';
		display: block;
		position: absolute;
		width: 0;
		height: 0;
		border-top: 9px solid #fff;
		border-left: 9px solid transparent;
		border-right: 9px solid transparent;
		bottom: -9px;
		left: 50%;
		margin-left: -5px;
	}

	&__input {
		display: block;
		width: 100%;
		text-align: center;
		font-weight: 400;
		font-size: 15px;
		line-height: 36px;
		padding: 0 5px;
		border: none;
		background-color: #d9d9d9;
		margin-bottom: 15px;

		&--pass {
			font-size: 12px;
		}
	}

	
}