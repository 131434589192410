@media (min-width: 1060px) and (max-width: 1600px){
    .container {
  		width: auto;
  		margin: 0 50px;
  		min-width: 930px;
    }
}

@media (min-width: 1272px){
	.content .gallery {
		width: 1125px;

		&--tile {
			width: 1145px;
		}
	}
}

@media (min-width: 1502px){
	
	.content .gallery {
		width: 1356px;

		&--tile {
			width: 1376px;
		}
	}
}

@media (min-width: 1600px) {
    .container {
  		width: 1500px;
    }
}

