html,
body {
  height: 100%;
}
body {
  font: 14px/19px 'elektra', sans-serif;
  font-weight: 300;
  color: #333333;
  background-color: #fff;
  -webkit-text-size-adjust: none;
}
body,
input,
textarea,
button {
  -webkit-font-smoothing: antialiased;
  outline: none !important;
}
ul,
li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
ins {
  text-decoration: none;
}
strong,
b {
  font-weight: 700;
}
hr {
  display: block;
  width: 143px;
  border-top: 3px solid #222222;
  margin: 20px 0;
}
h2 {
  font-family: 'molot', sans-serif;
  font-size: 47px;
  line-height: 1;
  margin-bottom: 25px;
}
h3 {
  font-size: 19px;
  line-height: 24px;
  font-weight: 400;
}
h3 i {
  display: inline-block;
  vertical-align: middle;
}
h4 {
  font-size: 14px;
  line-height: 19px;
  font-weight: 700;
}
a {
  color: #222222;
  text-decoration: none;
  outline: none !important;
  transition: all 0.3s ease;
}
a:hover {
  color: #cc0000;
  text-decoration: none;
}
a:focus {
  color: #222222;
}
img {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: gray;
  transition: all 0.3s ease;
}
img:hover {
  -webkit-filter: none;
  -moz-filter: none;
  -ms-filter: none;
  -o-filter: none;
  filter: none;
}
/*	=	Select */
.select {
  position: relative;
  z-index: 10;
  width: 210px;
  font-size: 19px;
  font-weight: 400;
  line-height: 24px;
  margin: 0 0 25px;
}
.select a {
  text-decoration: none !important;
}
.slct {
  display: block;
  background-color: #e8e8e8;
  width: 100%;
  padding: 10px 15px 9px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}
.slct:hover {
  background-color: #d0d0d0;
  color: #222222;
}
.slct i {
  float: right;
  color: #aaaaaa;
  font-size: 24px;
}
.drop {
  margin: 0 !important;
  padding: 0;
  width: 100%;
  display: none;
  position: absolute;
  background: #e8e8e8;
}
.drop li {
  list-style: none;
  cursor: pointer;
  display: block;
}
.drop a {
  display: block;
  padding: 10px 15px 9px;
}
.drop a:hover {
  background-color: #d0d0d0;
  color: #222222;
}
.header {
  padding: 12px 0;
  margin-bottom: 10px;
}
.header__phone {
  display: inline-block;
  font-size: 19px;
  line-height: 19px;
  color: #888888;
  margin: 50px 0 12px;
  text-decoration: none !important;
}
.header__phone::before {
  content: '';
  display: inline-block;
  background: url(../images/sprite.png) 0 -18px no-repeat;
  width: 16px;
  height: 17px;
  vertical-align: top;
  margin-right: 5px;
}
.logo {
  font-family: 'molot', sans-serif;
  font-size: 47px;
  font-weight: 400;
  line-height: 1;
  color: #333333;
  margin-top: 45px;
}
.logo a {
  display: inline-block;
  position: relative;
  width: 327px;
  height: 75px;
  background: url(../images/logo.png) 0 0 no-repeat;
  transition: none;
}
.logo a::before {
  content: '';
  display: block;
  position: absolute;
  background: url(../images/logo.png) 0 -75px no-repeat;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  z-index: 1;
  transition: opacity 0.3s;
}
.logo a:hover::before {
  opacity: 1;
}
.logo span {
  display: block;
  float: left;
  font-family: 'elektra', sans-serif;
  font-weight: 300;
  font-size: 19px;
  color: #888888;
}
.logo:active,
.logo:focus {
  text-decoration: none;
}
.navmain {
  margin: 12px 0;
}
.navmain li {
  display: inline-block;
  margin-left: 4px;
}
.navmain a {
  display: block;
  font-size: 19px;
  line-height: 24px;
  padding: 6px 10px 4px;
  text-transform: uppercase;
  color: #333333;
  text-decoration: none !important;
  transition: all 0.4s ease;
}
.navmain a:hover {
  color: #cc0000;
}
.navmain a.is-active {
  background-color: #efefef;
  color: #333333;
}
.wrapper {
  position: relative;
  min-height: 100%;
  height: auto !important;
  height: 100%;
}
.wrapper .empty {
  height: 80px;
}
.footer {
  position: relative;
  margin-top: -80px;
  height: 80px;
}
.footer-main {
  border-top: 2px solid #bfbfbf;
  padding: 27px 0;
  font-size: 14px;
  line-height: 19px;
}
.copy {
  float: left;
  line-height: 32px;
}
.share {
  float: right;
}
.share__title {
  display: inline-block;
  vertical-align: middle;
}
.share img {
  display: inline;
  -webkit-filter: none;
  -moz-filter: none;
  -ms-filter: none;
  -o-filter: none;
  filter: none;
}
.footer-login {
  display: inline-block;
  position: relative;
  top: -2px;
  margin-left: 30px;
}
.footer-login a {
  display: inline-block;
  font-size: 14px;
  line-height: 19px;
  padding: 8px 15px;
  color: #000;
  border-radius: 10px;
  text-decoration: none !important;
  position: relative;
  padding-left: 38px;
}
.footer-login a::before,
.footer-login a::after {
  content: '';
  background: url(../images/sprite.png) 0 0 no-repeat;
  width: 12px;
  height: 15px;
  display: block;
  position: absolute;
  left: 16px;
  top: 50%;
  margin-top: -9px;
  opacity: 1;
  transition: opacity 0.3s ease;
}
.footer-login a::after {
  background-position: -15px 0;
  opacity: 0;
}
.footer-login__in {
  display: inline-block;
  position: relative;
  z-index: 999;
}
.footer-login__in:hover {
  color: #cc0000;
}
.footer-login__in:hover::before {
  opacity: 0;
}
.footer-login__in:hover::after {
  opacity: 1;
}
.footer-login__in.is-active {
  background-color: #ebebeb;
}
.footer-login__in.is-active:hover {
  color: #000;
}
.footer-login__in.is-active:hover::before {
  opacity: 1;
}
.footer-login__in.is-active:hover::after {
  opacity: 0;
}
.footer-login__out {
  display: none !important;
}
.form {
  display: block;
  position: absolute;
  bottom: 48px;
  left: 50%;
  margin-left: -112px;
  width: 225px;
  height: 147px;
  padding: 30px 25px;
  background-color: #fff;
  border-radius: 9px;
  text-align: center;
  display: none;
  z-index: 999;
}
.form:before {
  content: '';
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-top: 9px solid #fff;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  bottom: -9px;
  left: 50%;
  margin-left: -5px;
}
.form__input {
  display: block;
  width: 100%;
  text-align: center;
  font-weight: 400;
  font-size: 15px;
  line-height: 36px;
  padding: 0 5px;
  border: none;
  background-color: #d9d9d9;
  margin-bottom: 15px;
}
.form__input--pass {
  font-size: 12px;
}
.main {
  padding-bottom: 70px;
}
.shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: none;
  z-index: 999;
}
.content ul {
  margin: 10px 0;
}
.content ul li {
  position: relative;
  padding-left: 20px;
}
.content ul li::before {
  content: '—';
  position: absolute;
  left: 0;
  top: 0;
}
.content .gallery li,
.content .select li {
  padding-left: 0;
}
.content .gallery li::before,
.content .select li::before {
  display: none;
}
.content--info {
  min-height: 300px;
  position: relative;
}
.content--info hr {
  margin: 0;
}
.content--info hr:nth-of-type(2) {
  position: absolute;
  bottom: 0;
}
.content--info h2 {
  margin: 20px 0 10px;
}
.content--history {
  background: url(../images/history-bg.png) center no-repeat;
  background-size: cover;
  padding: 40px 0;
  margin: -36px 0;
}
.lead {
  font-size: 19px;
  line-height: 24px;
  margin-bottom: 10px;
}
.bold-text {
  font-weight: 400;
}
.about {
  display: inline-block;
  font-size: 19px;
  line-height: 19px;
  text-transform: uppercase;
  color: #cc0000;
  border-bottom: 2px solid #cc0000;
  margin: 10px 0;
}
.about:hover {
  color: #cc0000;
  border-bottom-color: transparent;
}
.index-slider {
  margin: 45px 0 4px;
}
.index-slider-item {
  margin: 0 2px;
  outline: none !important;
}
.index-slider-item a {
  outline: none !important;
}
.index-slider-arrows {
  line-height: 0;
  margin-bottom: 40px;
}
.index-slider-arrows .slick-arrow {
  width: 113px;
  height: 33px;
  border: none;
  position: relative;
}
.index-slider-arrows .slick-arrow.slick-prev {
  margin-right: 4px;
  left: 0;
}
.index-slider-arrows .slick-arrow.slick-next {
  right: 0;
}
.slick-arrow {
  background-color: #e3e3e3;
  width: 27px;
  height: 100%;
  top: 0;
  position: absolute;
  border: none;
  transition: background-color 0.3s ease;
}
.slick-arrow i {
  display: block;
  position: absolute;
  background: url(../images/controls.png) 0 0 no-repeat;
  width: 9px;
  height: 14px;
  top: 50%;
  margin-top: -7px;
  left: 50%;
  margin-left: -5px;
}
.slick-arrow.slick-prev {
  left: -37px;
}
.slick-arrow.slick-prev i {
  background-position: 0 0;
}
.slick-arrow.slick-next {
  right: -37px;
}
.slick-arrow.slick-next i {
  background-position: -11px 0;
}
.slick-arrow:hover {
  background-color: #cccccc;
}
.feedback {
  color: #666666;
}
.sidemenu {
  display: block;
  background-color: #fff;
  border: 3px solid #222222;
  padding: 25px 25px;
  min-height: 300px;
  transition: all 0.3s ease;
}
.sidemenu h3 {
  font-size: 14px;
  line-height: 19px;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 0;
}
.sidemenu a {
  transition: none;
}
.sidemenu a:focus {
  text-decoration: none;
}
.sidemenu li {
  margin-bottom: 25px;
}
.sidemenu li.active a {
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
}
.sidemenu.is-fixed {
  position: fixed;
  top: 10px;
  z-index: 99;
}
.gallery {
  margin-bottom: 60px;
  width: 900px;
}
.gallery--inline {
  margin: 40px 0;
  width: 100% !important;
  white-space: nowrap;
  overflow: hidden;
  height: 257px;
}
.gallery--inline li {
  display: inline-block;
  vertical-align: top;
  height: 257px;
}
.gallery--inline a {
  display: block;
  width: auto;
  height: 100%;
}
.gallery--inline img {
  width: auto;
  height: 100%;
}
.gallery--opacity {
  opacity: 0.55;
}
.gallery--tile {
  margin: 10px -10px 60px !important;
  overflow: hidden;
  height: 266px;
  width: 920px;
}
.gallery--tile li {
  float: left;
  overflow: hidden;
}
.gallery--tile .gallery-item {
  margin-bottom: 6px;
}
.gallery-item {
  float: left;
  margin-left: 10px;
  width: 220px;
  height: 130px;
  overflow: hidden;
}
.gallery-item--video {
  width: 445px;
  height: 266px;
  position: relative;
}
.gallery-item--video span {
  display: block;
  position: absolute;
  font-size: 13px;
  font-weight: 400;
  color: #fff;
  line-height: normal;
  left: 0;
  bottom: 0;
  padding: 15px 20px;
}
.gallery-item--video::before,
.gallery-item--video::after {
  content: '';
  display: block;
  position: absolute;
  background: url(../images/sprite.png) 0 -128px no-repeat;
  width: 84px;
  height: 84px;
  left: 50%;
  top: 50%;
  margin-left: -42px;
  margin-top: -42px;
  z-index: 5;
}
.gallery-item--video::after {
  opacity: 0;
  transition: opacity 0.3s ease;
}
.gallery-item--video:hover::after {
  opacity: 1;
}
.gallery-item img {
  width: 100%;
  height: auto;
}
.gallery-item:hover img {
  -webkit-filter: none;
  -moz-filter: none;
  -ms-filter: none;
  -o-filter: none;
  filter: none;
}
.icon {
  display: inline-block;
  vertical-align: middle;
  background: url(../images/sprite.png) 0 -71px no-repeat;
  width: 31px;
  height: 28px;
  margin-right: 15px;
}
.icon--album {
  width: 31px;
  height: 28px;
  background-position: 0 -71px;
}
.icon--video {
  width: 30px;
  height: 34px;
  background-position: 0 -37px;
}
.popup {
  display: none;
  width: 860px;
}
.popup h4 {
  font-weight: 400;
  margin-top: 30px;
}
.popup .gallery--tile {
  width: auto;
}
.popup .popup-close {
  display: block;
  position: fixed;
  width: 25px;
  height: 25px;
  background: url(../images/sprite.png) 0 -100px no-repeat;
  top: 25px;
  right: 25px;
  z-index: 99999;
}
.popup .popup-close:hover {
  opacity: 0.5;
}
.popup-item__img {
  width: 100%;
  overflow: hidden;
}
.popup-item__img img {
  width: 100%;
}
.popup-item img {
  -webkit-filter: none;
  -moz-filter: none;
  -ms-filter: none;
  -o-filter: none;
  filter: none;
}
.popup-item__caption {
  padding: 25px 35px 40px;
}
.popup-item .share {
  float: none;
  border-top: 1px solid #bfbfbf;
  margin: 25px 0;
  padding-top: 20px;
}
.popup-gallery {
  width: 980px;
  height: auto;
  position: fixed;
  top: 5%;
  bottom: 5%;
  left: 50%;
  margin-left: -490px;
  z-index: 9999;
  height: 90%;
  padding: 0 100px;
}
.popup-gallery .popup-item {
  height: 100%;
  width: 100% !important;
  overflow: auto;
}
.popup-gallery .bx-wrapper {
  height: 100%;
  background-color: #fff;
}
.popup-gallery .bx-wrapper .bx-viewport {
  border: none;
  box-shadow: none;
  left: 0;
  height: 100% !important;
  overflow: auto;
}
.popup-gallery .bx-wrapper .popup-slider {
  height: 100%;
}
.popup-gallery .bx-wrapper .bx-controls-direction a {
  display: block;
  width: 100px;
  height: 100%;
  top: 0;
  margin-left: 0;
  margin-top: 0;
  background: rgba(0, 0, 0, 0);
  transition: background-color 0.3s;
}
.popup-gallery .bx-wrapper .bx-controls-direction a::before {
  content: '';
  display: block;
  position: absolute;
  width: 22px;
  height: 37px;
  top: 50%;
  left: 50%;
  margin-top: -18px;
  margin-left: -11px;
  transition: opacity 0.3s;
}
.popup-gallery .bx-wrapper .bx-controls-direction a:hover {
  background: rgba(0, 0, 0, 0.2);
}
.popup-gallery .bx-wrapper .bx-controls-direction a:hover::before {
  opacity: 0.5;
}
.popup-gallery .bx-wrapper .bx-prev {
  left: -100px;
}
.popup-gallery .bx-wrapper .bx-prev::before {
  background: url(../images/controls2.png) 0 0 no-repeat;
}
.popup-gallery .bx-wrapper .bx-next {
  right: -100px;
}
.popup-gallery .bx-wrapper .bx-next::before {
  background: url(../images/controls2.png) -22px 0 no-repeat;
}
.popup-album {
  padding: 0 60px;
  width: 980px;
  position: fixed;
  top: 10%;
  bottom: 10%;
  left: 50%;
  margin-left: -490px;
  z-index: 9999;
}
.popup-album .popup-album-inner {
  padding: 25px 35px 35px;
  background-color: #fff;
  height: 100%;
  overflow: auto;
}
.popup-album .gallery {
  margin: 45px -6px 0 !important;
  height: auto;
  width: auto;
}
.popup-album .gallery-item {
  width: 188px;
  height: 112px;
  margin-left: 6px;
}
.fancybox-skin {
  border-radius: 0 !important;
}
.fancybox-nav {
  width: 60px;
}
.fancybox-nav span {
  background: url(../images/controls2.png) 0 0 no-repeat;
  width: 22px;
  height: 37px;
  visibility: visible;
  top: 35%;
}
.fancybox-nav:hover {
  opacity: 0.5;
}
.fancybox-next {
  right: -60px;
}
.fancybox-next span {
  background-position: -22px 0;
  right: 0;
}
.fancybox-prev {
  left: -60px;
}
.fancybox-prev span {
  background-position: 0 0;
  left: 0;
}
.fancybox-close {
  width: 25px;
  height: 25px;
  background: url(../images/sprite.png) 0 -100px no-repeat;
  top: 0;
  right: -45px;
}
.fancybox-close:hover {
  opacity: 0.5;
}
.search {
  overflow: hidden;
  text-align: right;
  position: relative;
  margin-bottom: 25px;
}
.search::before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #000;
  left: 0;
  top: 16px;
}
.search fieldset {
  float: right;
  position: relative;
}
.search input[type="text"] {
  border: 2px solid #000;
  background-color: #fff;
  border-radius: 0;
  height: 33px;
  line-height: 29px;
  width: 175px;
  padding: 0 40px 0 5px;
  transition: width 0.3s ease;
}
.search input[type="text"]:focus {
  width: 250px;
}
.search button {
  display: block;
  position: absolute;
  width: 36px;
  height: 100%;
  background-color: #000;
  color: #fff;
  text-align: center;
  font-size: 16px;
  line-height: 33px;
  right: 0;
  top: 0;
  border: none;
  border-radius: 0;
  transition: background-color 0.3s ease;
}
.search button:hover {
  background-color: #cc0000;
}
.search span {
  display: block;
  clear: right;
  font-size: 12px;
  font-weight: 400;
}
.content .doc-list {
  margin: 0 65px;
}
.doc {
  position: relative;
  padding-left: 45px !important;
  min-height: 37px;
  margin-bottom: 20px;
}
.doc:before {
  content: '' !important;
  display: block !important;
  background: url(../images/sprite.png) 0 -214px no-repeat;
  width: 30px;
  height: 37px;
  left: 0;
  top: 2px !important;
}
.doc__title {
  display: block;
  font-weight: 400;
  margin-bottom: 3px;
}
.doc__descr {
  margin-bottom: 5px;
}
.doc__icon {
  display: inline-block;
  background: url(../images/sprite.png) -35px -215px no-repeat;
  width: 16px;
  height: 16px;
  position: relative;
}
.doc__icon::before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: url(../images/sprite.png) -35px -235px no-repeat;
  left: 0;
  top: 0;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.doc__icon:hover::before {
  opacity: 1;
}
.doc__icon--dwnld {
  background-position: -35px -215px;
}
.doc__icon--dwnld::before {
  background-position: -35px -235px;
}
.doc__icon--resize {
  background-position: -54px -215px;
}
.doc__icon--resize::before {
  background-position: -54px -235px;
}
.doc__icon--lock {
  background-position: -73px -215px;
}
.doc__icon--lock::before {
  background-position: -73px -235px;
}
.map {
  margin: 30px 0 0;
}
.map #map {
  width: 100%;
  height: 536px;
}
/* Template-specific stuff
 *
 * Customizations just for the template; these are not necessary for anything
 * with disabling the responsiveness.
 */
/* Account for fixed navbar */
body {
  min-width: 960px;
}
/* Finesse the page header spacing */
.page-header {
  margin-bottom: 30px;
}
.page-header .lead {
  margin-bottom: 10px;
}
/* Non-responsive overrides
 *
 * Utilitze the following CSS to disable the responsive-ness of the container,
 * grid system, and navbar.
 */
/* Reset the container */
.container {
  width: 930px;
  max-width: none !important;
}
/* Demonstrate the grids */
.container .navbar-header,
.container .navbar-collapse {
  margin-right: 0;
  margin-left: 0;
}
/* Always float the navbar header */
.navbar-header {
  float: left;
}
/* Undo the collapsing navbar */
.navbar-collapse {
  display: block !important;
  height: auto !important;
  padding-bottom: 0;
  overflow: visible !important;
}
.navbar-toggle {
  display: none;
}
.navbar-collapse {
  border-top: 0;
}
.navbar-brand {
  margin-left: -15px;
}
/* Always apply the floated nav */
.navbar-nav {
  float: left;
  margin: 0;
}
.navbar-nav > li {
  float: left;
}
.navbar-nav > li > a {
  padding: 15px;
}
/* Redeclare since we override the float above */
.navbar-nav.navbar-right {
  float: right;
}
/* Undo custom dropdowns */
.navbar .navbar-nav .open .dropdown-menu {
  position: absolute;
  float: left;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-width: 0 1px 1px;
  border-radius: 0 0 4px 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}
.navbar-default .navbar-nav .open .dropdown-menu > li > a {
  color: #333;
}
.navbar .navbar-nav .open .dropdown-menu > li > a:hover,
.navbar .navbar-nav .open .dropdown-menu > li > a:focus,
.navbar .navbar-nav .open .dropdown-menu > .active > a,
.navbar .navbar-nav .open .dropdown-menu > .active > a:hover,
.navbar .navbar-nav .open .dropdown-menu > .active > a:focus {
  color: #fff !important;
  background-color: #428bca !important;
}
.navbar .navbar-nav .open .dropdown-menu > .disabled > a,
.navbar .navbar-nav .open .dropdown-menu > .disabled > a:hover,
.navbar .navbar-nav .open .dropdown-menu > .disabled > a:focus {
  color: #999 !important;
  background-color: transparent !important;
}
@media (min-width: 1060px) and (max-width: 1600px) {
  .container {
    width: auto;
    margin: 0 50px;
    min-width: 930px;
  }
}
@media (min-width: 1272px) {
  .content .gallery {
    width: 1125px;
  }
  .content .gallery--tile {
    width: 1145px;
  }
}
@media (min-width: 1502px) {
  .content .gallery {
    width: 1356px;
  }
  .content .gallery--tile {
    width: 1376px;
  }
}
@media (min-width: 1600px) {
  .container {
    width: 1500px;
  }
}
