// Main



.main {
	padding-bottom: 70px;
}

.shadow {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	background-color: rgba(0,0,0,0.7);
	display: none;
	z-index: 999;
}

.content {
	ul {
		margin: 10px 0;

		li {
			position: relative;
			padding-left: 20px;

			&::before {
				content: '—';
				position: absolute;
				left: 0;
				top: 0;
			}
		}
	}

	.gallery,
	.select {
		li {
			padding-left: 0;

			&::before {
				display: none;
			}
		}
	}

	&--info {
		min-height: 300px;
		position: relative;

		hr {
			margin: 0;

			&:nth-of-type(2){
				position: absolute;
				bottom: 0;
			}
		}

		h2 {
			margin: 20px 0 10px;
		}
	}

	&--history {
		background: url(../images/history-bg.png) center no-repeat;
		background-size: cover;
		padding: 40px 0;
		margin: -36px 0;
	}
}

.lead {
	font-size: 19px;
	line-height: 24px;
	margin-bottom: 10px;
}

.bold-text {
	font-weight: 400;
}

.about {
	display: inline-block;
	font-size: 19px;
	line-height: 19px;
	text-transform: uppercase;
	color: #cc0000;
	border-bottom: 2px solid #cc0000;
	margin: 10px 0;

	&:hover {
		color: #cc0000;
		border-bottom-color: transparent;
	}
}

// Index slider

.index-slider {
	margin: 45px 0 4px;
}

.index-slider-item {
	margin: 0 2px;
	outline: none !important;

	a {
		outline: none !important;
	}
}

.index-slider-arrows {
	line-height: 0;
	margin-bottom: 40px;

	.slick-arrow {
		width: 113px;
		height: 33px;
		border: none;
		position: relative;

		&.slick-prev {
			margin-right: 4px;
			left: 0;
		}

		&.slick-next {
			right: 0;
		}
	}

	

}

.slick-arrow {
	background-color: #e3e3e3;
	width: 27px;
	height: 100%;
	top: 0;
	position: absolute;
	border: none;
	.m-trans(background-color);

	i {
		display: block;
		position: absolute;
		background: url(../images/controls.png) 0 0 no-repeat;
		width: 9px;
		height: 14px;
		top: 50%;
		margin-top: -7px;
		left: 50%;
		margin-left: -5px;
	}

	&.slick-prev {
		left: -37px;

		i{
			background-position: 0 0;
		}
	}

	&.slick-next {
		right: -37px;

		i{
			background-position: -11px 0;
		}
	}

	&:hover {
		background-color: #cccccc;
	}
}

.feedback {
	color: #666666;
}

.sidemenu {
	display: block;
	background-color: #fff;
	border: 3px solid #222222;
	padding: 25px 25px;
	min-height: 300px;
	.m-trans(all);

	h3 {
		font-size: 14px;
		line-height: 19px;
		font-weight: 700;
		text-transform: uppercase;
		margin-top: 0;
	}

	a {
		transition: none;

		&:focus {
			text-decoration: none;
		}
	}

	li {
		margin-bottom: 25px;

		&.active a{
			font-weight: 700;
			text-transform: uppercase;
			text-decoration: none;
		}
	}

	&.is-fixed {
		position: fixed;
		top: 10px;
		z-index: 99;
	}
}

.gallery {
	margin-bottom: 60px;
	width: 900px;

	&--inline {
		margin: 40px 0;
		width: 100% !important;
		white-space: nowrap;
		overflow: hidden;
		height: 257px;

		li {
			display: inline-block;
			vertical-align: top;
			height: 257px;
		}

		a {
			display: block;
			width: auto;
			height: 100%;
		}

		img {
			width: auto;
			height: 100%;
		}
	}

	&--opacity {
		opacity: 0.55;
	}

	&--tile {
		margin: 10px -10px 60px!important;
		overflow: hidden;
		height: 266px;
		width: 920px;


		li {
			float: left;
			overflow: hidden;
		}

		.gallery-item {
			margin-bottom: 6px;
		}
	}
}




.gallery-item {
	float: left;
	margin-left: 10px;
	width: 220px;
	height: 130px;
	overflow: hidden;

	&--video {
		width: 445px;
		height: 266px;
		position: relative;

		span {
			display: block;
			position: absolute;
			font-size: 13px;
			font-weight: 400;
			color: #fff;
			line-height: normal;
			left: 0;
			bottom: 0;
			padding: 15px 20px;
		}

		&::before,
		&::after {
			content: '';
			display: block;
			position: absolute;
			background: url(../images/sprite.png) 0 -128px no-repeat;
			width: 84px;
			height: 84px;
			left: 50%;
			top: 50%;
			margin-left: -42px;
			margin-top: -42px;
			z-index: 5;
		}

		&::after {
			opacity: 0;
			.m-trans(opacity);
		}

		&:hover {
			
			&::after {
				opacity: 1;
			}
		}
	}

	img {
		width: 100%;
		height: auto;
	}

	&:hover {
		img {
			-webkit-filter: none;
			-moz-filter: none;
			-ms-filter: none;
			-o-filter: none;
			filter: none;
			filter: none; 
		}
	}
}

.icon {
	display: inline-block;
	vertical-align: middle;
	background: url(../images/sprite.png) 0 -71px no-repeat;
	width: 31px;
	height: 28px;
	margin-right: 15px;

	&--album {
		width: 31px;
		height: 28px;
		background-position: 0 -71px;
	}

	&--video {
		width: 30px;
		height: 34px;
		background-position: 0 -37px;
	}
}

.popup {
	display: none;
	width: 860px;
	// overflow: hidden;

	h4 {
		font-weight: 400;
		margin-top: 30px;
	}
	
	.gallery--tile {
		width: auto;
		// overflow-y: auto;
	}

	.popup-close {
		display: block;
		position: fixed;
		width: 25px;
	    height: 25px;
	    background: url(../images/sprite.png) 0 -100px no-repeat;
	    top: 25px;
	    right: 25px;
	    z-index: 99999;

	    &:hover {
	    	opacity: 0.5;
	    }
	}
}


.popup-item {
	

	&__img {
		width: 100%;
		overflow: hidden;

		img {
			width: 100%;
		}
	}

	img {
		-webkit-filter: none;
        -moz-filter: none;
        -ms-filter: none;
        -o-filter: none;
        filter: none;
        filter: none; 
	}

	&__caption {
		padding: 25px 35px 40px;
	}

	.share {
		float: none;
		border-top: 1px solid #bfbfbf;
		margin: 25px 0;
		padding-top: 20px;
	}
}

.popup-gallery {
	width: 980px;
	height: auto;
	position: fixed;
	top: 5%;
	bottom: 5%;
	left: 50%;
	margin-left: -490px;
	z-index: 9999;
	height: 90%;
	padding: 0 100px;

	.popup-item {
		height: 100%;
		width: 100% !important;
		overflow: auto;
	}

	.bx-wrapper {
		height: 100%;
		background-color: #fff;

		.bx-viewport {
			border: none;
			box-shadow: none;
			left: 0;
			height: 100% !important;
			overflow: auto;
		}

		.popup-slider {
			height: 100%;
		}

		.bx-controls-direction a {
			display: block;
			width: 100px;
			height: 100%;
			top: 0;
			margin-left: 0;
			margin-top: 0;
			background: rgba(0,0,0,0);
			transition: background-color 0.3s;

			&::before {
				content:'';
				display: block;
				position: absolute;
				width: 22px;
				height: 37px;
				top: 50%;
				left: 50%;
				margin-top: -18px;
				margin-left: -11px;	
				transition: opacity 0.3s;
			}

			&:hover {
		    	background: rgba(0,0,0,0.2);

		    	&::before {
		    		opacity: 0.5;
		    	}
		    }
		}

		.bx-prev {
			left: -100px;

			&::before {
				background: url(../images/controls2.png) 0 0 no-repeat;	
			}
		}

		.bx-next {
			right: -100px;

			&::before {
				background: url(../images/controls2.png) -22px 0 no-repeat;
			}
		}
	}

}


.popup-album {
	padding: 0 60px;
	width: 980px;
	position: fixed;
	top: 10%;
	bottom: 10%;
	left: 50%;
	margin-left: -490px;
	z-index: 9999;

	.popup-album-inner {
		padding: 25px 35px 35px;
		background-color: #fff;
		height: 100%;
		overflow: auto;
	}

	.gallery {
		margin: 45px -6px 0 !important;
	    height: auto;
    	width: auto;
	}

	.gallery-item {
		width: 188px;
		height: 112px;
		margin-left: 6px;
	}
}

// Fancy styles

.fancybox-skin {
	border-radius: 0 !important;
}

.fancybox-nav {
	width: 60px;

	span {
		background: url(../images/controls2.png) 0 0 no-repeat;
		width: 22px;
		height: 37px;
		visibility: visible;
		top: 35%;
	}

	&:hover {
		opacity: 0.5;
	}
}

.fancybox-next {
	right: -60px;

	span {
		background-position: -22px 0;
		right: 0;
	}
}

.fancybox-prev {
	left: -60px;

	span {
		background-position: 0 0;
		left: 0;
	}
}

.fancybox-close {
	width: 25px;
	height: 25px;
	background: url(../images/sprite.png) 0 -100px no-repeat;
	top: 0;
	right: -45px;

	&:hover {
		opacity: 0.5;
	}
}

.search {
	overflow: hidden;
	text-align: right;
	position: relative;
	margin-bottom: 25px;

	&::before {
		content: '';
		display: block;
		position: absolute;
		width: 100%;
		height: 2px;
		background-color: #000;
		left: 0;
		top: 16px;
	}

	fieldset {
		float: right;
		position: relative;
	}

	input[type="text"] {
		border: 2px solid #000;
		background-color: #fff;
		border-radius: 0;
		height: 33px;
		line-height: 29px;
		width: 175px;
		padding: 0 40px 0 5px;
		.m-trans(width);

		&:focus {
			width: 250px;
		}
	}

	button {
		display: block;
		position: absolute;
		width: 36px;
		height: 100%;
		background-color: #000;
		color: #fff;
		text-align: center;
		font-size: 16px;	
		line-height: 33px;
		right: 0;
		top: 0;
		border: none;
		border-radius: 0;
		.m-trans(background-color);

		&:hover {
			background-color: #cc0000;
		}
	}

	span {
		display: block;
		clear: right;
		font-size: 12px;
		font-weight: 400;
	}
}

.content .doc-list {
	margin: 0 65px;
}

.doc {
	position: relative;
	padding-left: 45px !important;
	min-height: 37px;
	margin-bottom: 20px;

	&:before {
		content: '' !important;
		display: block !important;
		background: url(../images/sprite.png) 0 -214px no-repeat;
		width: 30px;
		height: 37px;
		left: 0;
		top: 2px !important;
	}

	&__title {
		display: block;
		font-weight: 400;
		margin-bottom: 3px;
	} 

	&__descr {
		margin-bottom: 5px;
	}
	

	&__icon {
		display: inline-block;
		background: url(../images/sprite.png) -35px -215px no-repeat;
		width: 16px;
		height: 16px;
		position: relative;

		&::before {
			content: '';
			display: block;
			position: absolute;
			width: 100%;
			height: 100%;
			background: url(../images/sprite.png) -35px -235px no-repeat;
			left: 0;
			top: 0;
			opacity: 0;
			.m-trans(opacity);
		}

		&:hover::before {
			opacity: 1;
		}

		&--dwnld {
			background-position: -35px -215px;

			&::before {
				background-position: -35px -235px;
			}
		}
		
		&--resize {
			background-position: -54px -215px;

			&::before {
				background-position: -54px -235px;
			}
		}

		&--lock {
			background-position: -73px -215px;

			&::before {
				background-position: -73px -235px;
			}
		}
	}
}

.map {
	margin: 30px 0 0;

	#map {
		width: 100%;
		height: 536px;
	}
}